<template>
  <vue-final-modal class="modal-tournament-leaders">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getLabel('leaderboard.title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('tournament-leaders')" />
      </div>
    </div>

    <div class="vfm__body">
      <tournament-leaderboard
        class="tournament__leaderboard"
        :currentLocaleCommonContent="currentLocaleCommonContent"
        :defaultLocaleCommonContent="defaultLocaleCommonContent"
        :tournamentDefiniteData="tournamentData"
        isModal
      />
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CITournamentCommon, CITournamentPage, ITournament } from '~/types';

  const props = defineProps<{
    tournamentData: ITournament;
    currentLocaleCommonContent: Maybe<CITournamentCommon>;
    defaultLocaleCommonContent: Maybe<CITournamentCommon>;
    tournamentContent: Maybe<CITournamentPage>;
  }>();

  const { getContent } = useProjectMethods();

  const getLabel = (path: string): string =>
    getContent(props.currentLocaleCommonContent, props.defaultLocaleCommonContent, path);

  const { closeModal } = useModalStore();
</script>

<style src="~/assets/styles/components/modal/tournament-leaders.scss" lang="scss" />
